<template>
    <div class="page-wrapper plug">
        <div class="container" style="height: 100%;">
            <div class="row" style="height: 100%; row-gap: 2rem;">
                <div class="col-lg-5">
                    <div class="text-center">
                        <router-link to="/">
                            <img src="@/img/logo-1.png" alt="Klub Partnerov Pirelli"/>
                        </router-link>
                    </div>
                </div>
                <div class="col-12 text-center d-flex flex-column justify-content-center align-items-center">
                    <h2 class="mb-4">
                        Vážení členovia klubu Pirelli,
                    </h2>
                    <div class="row">
                        <p class="f24 col-12 col-xl-8 offset-xl-2">
                            od 1.6. do 10.6.2024 bude prebiehať presun na novú webovú stránku a&nbsp;z&nbsp;tohto dôvodu bude stránka nedostupná. Pokyny k&nbsp;novému vstupu do systému obdržíte na vašu e-mailovú adresu. Ďakujeme za pochopenie!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PlugComponent",
}
</script>

<style lang="scss">
.page-wrapper.plug {
    background-image: url("@/img/plug-bg.png");
    height: 100vh;


    @media (min-width: 992px) {
        &-page-text {
            max-width: 550px;
            margin: 9rem 0 9.5rem;
        }

        .box {
            margin-top: 7.5rem;
        }
    }
}
</style>
