export const routes = [
    {
        path: '',
        component: () => import('@/views/AppComponent'),
        children: [
            {
                path: '',
                name: 'home',
                meta: {requiresAuth: true},
                component: () => import('@/components/modules/home/HomeComponent')
            },
            // {
            //     path: 'aktualnosci',
            //     name: 'news',
            //     meta: {requiresAuth: true},
            //     component: () => import('@/components/modules/news/NewsComponent')
            // },
            {
                path: 'aktualnosci/:id',
                name: 'news-single',
                meta: {requiresAuth: true},
                component: () => import('@/components/modules/news/NewsSingleComponent')
            },
            {
                path: 'moje-konto',
                component: () => import('@/components/modules/account/AccountComponent'),
                children: [
                    {
                        path: '',
                        name: 'account',
                        meta: {requiresAuth: true},
                        component: () => import('@/components/modules/account/AccountIndexComponent'),
                    },
                    {
                        path: 'edycja-danych',
                        name: 'editAccount',
                        meta: {requiresAuth: true},
                        component: () => import('@/components/modules/account/EditAccountComponent'),
                    },
                ]
            },
            {
                path: 'moje-premie',
                name: 'bonuses',
                meta: {requiresAuth: true},
                component: () => import('@/components/modules/bonuses/BonusesComponent')
            },
            {
                path: 'zasady',
                name: 'rules',
                meta: {requiresAuth: true},
                component: () => import('@/components/modules/rules/RulesComponent')
            },
            {
                path: 'check-agreements',
                name: 'checkAgreements',
                meta: {requiresAuth: true},
                component: () => import('@/components/modules/auth/CheckAgreementsComponent')
            },
        ]
    },
    {
        path: '',
        component: () => import('@/views/AuthComponent'),
        children: [
            {
                path: '',
                component: () => import('@/components/modules/auth/LoginComponent'),
                children: [
                    {
                        path: 'login',
                        name: 'login',
                        component: () => import('@/components/modules/auth/LoginFormComponent'),
                    },
                    {
                        path: 'verify/:id/:hash',
                        name: 'verify',
                        component: () => import('@/components/modules/auth/LoginFormComponent')
                    },
                    {
                        path: 'forgot-password',
                        name: 'forgotPassword',
                        component: () => import('@/components/modules/auth/ForgotPasswordComponent')
                    },
                    {
                        path: 'reset-password/:token?',
                        name: 'resetPassword',
                        component: () => import('@/components/modules/auth/ResetPasswordComponent')
                    },
                ]
            },
            {
                path: 'register',
                name: 'register',
                component: () => import('@/components/modules/auth/RegisterComponent')
            },
        ]
    },
    {
        path: '*',
        name: 'error-404',
        component: () => import('@/components/error/Error404')
    },
]
